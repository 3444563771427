import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { environment } from "../environments/environment";
import { WorkspacePermissionService } from "../shared/services/workspace-permissions.service";

@Injectable({
    providedIn: 'root'
})
export class DeskManagerGuardService  {
    constructor(
        private workspacePermissionService: WorkspacePermissionService,
        private router: Router
    ) {}
    async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        try {
            if (!await this.workspacePermissionService.canViewCollaborateScreen()) {
                this._navigateToHomePage();
                return false;
            }

            return true;
            
        } catch (error) {
            this._navigateToHomePage();
            return false;
        }
    }

    private _navigateToHomePage = () => {
        this.router.navigate([environment.globals.firstAppPage]);
    }
    
}